/* eslint-disable */
import router from "@/router/router.js";
import { Message } from "view-design";

const state = {};
const mutations = {};
const getters = {};
const actions = {
  linkToDemandDetail({ rootState }, opt) {
    console.log(opt, "opt");
    router.push({
      path: `/home/demandDetail?id=${opt.id}&isFollow=${opt.isFollow}&exhibitionId=${rootState.exhibitionId}`,
    });
  },
  linkToNewsDetails({ rootState }, id) {
    router.push({
      path: `/home/newsDetails?newId=${id}&exhibitionId=${rootState.exhibitionId}`,
    });
  },
  linkToExhibitorDetails({ rootState }, id) {
    router.push({
      path: `/exhibitor-detail/${id}?exhibitionId=${rootState.exhibitionId}`,
    });
  },
  linkToProductDetais({ rootState }, id) {
    router.push({
      path: `/product-detail?productId=${id}&exhibitionId=${rootState.exhibitionId}`,
    });
  },
  linkToLogin({ rootState }) {
    router.push(`/login/${rootState.exhibitionId}`);
  },
  linkToRegSteps({ rootState, rootGetters, dispatch }) {
    let user = rootGetters.getUser;
    if (user && user.inMember && user.inMember.userRole && user.inMember.userRole != "VISITOR" && user.inMember.userRole != "Visitor") {
      Message.warning("You have already registered!");
      return;
    }
    if (rootState.login && rootState.user && rootState.user.userId) {
      dispatch(
        "linkTo/linkToRegForm",
        {
          type: "next",
          phone: rootState.user.phone,
        },
        {
          root: true,
        }
      );
    } else {
      router.push(`/regStep/${rootState.exhibitionId}`);
    }
  },
  linkToTour({ rootState, dispatch }) {
    router.push(`/home/tour/${rootState.exhibitionId}`);
  },

  linkToTourByExhibitorId({ rootState, dispatch }, id) {
    router.push(`/home/tour/${rootState.exhibitionId}?exhibitorId=${id}`);
  },

  async linkToRegForm({ rootState, getters, dispatch, commit }, opt) {
    let res = await dispatch(
      "getCustomForm",
      {
        itemId: rootState.exhibitionId,
        formType: "show.form.visitor.reg.en",
      },
      {
        root: true,
      }
    );
    if (res && res.getForm && res.getForm.items) {
      // 是否有自定义表单
      console.log("有自定义表单");
      if (opt.type == "next") {
        router.push({
          path: `/regCustom/${rootState.exhibitionId}?email=${opt.email || ""}`,
        });
      }
      if (opt.type == "no") {
        router.push({
          path: `/regCustom/${rootState.exhibitionId}?isCover=true&email=${opt.email || ""}`,
        });
      }
    } else {
      console.log("没有自定义表单");
      if (opt.type == "next") {
        router.push({
          path: `/regInfo/${rootState.exhibitionId}?email=${opt.email || ""}`,
        });
      }
      if (opt.type == "no") {
        router.push({
          path: `/regInfo/${rootState.exhibitionId}?isCover=true&email=${opt.email || ""}&edit=${opt.edit}`,
        });
      }
    }
  },
  // 活动链接(展商秀2.0)
  LinkAct({ rootState }, opt) {
    // opt.acvitityType === 'EXTERNAL_LINK'
    if (opt.externalLink) {
      // 外链
      window.open(opt.externalLink);
      // location.href = opt.externalLink;
    } else {
      if (opt.hasLive && opt.liveAuditState === "PASS") {
        // 直播 (有直播并且审核通过)
        router.push(`/home/activity-details?id=${opt.id}&exhibitionId=${rootState.exhibitionId}`);
      } else {
        // (普通活动)  英文版没有直播
        router.push(`/home/activity-nolive?id=${opt.id}&exhibitionId=${rootState.exhibitionId}`);
      }
    }
  },
  // 活动报名
  LinkActReg({ rootState }, id) {
    router.push(`/home/activity-join?id=${id}&exhibitionId=${rootState.exhibitionId}`);
  },
  LinkHome({}, id) {
    router.push(`/home/index/${id}`);
  },
  linkToForget() {
    router.push("/regForget");
  },
  linkToPassword() {
    router.push("/regPassword");
  },
};
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
