import Vue from 'vue'
import App from './App.vue'
import router from './router/router'
import store from './store/store'
import VueCookies from 'vue-cookies'
import VideoPlayer from 'vue-video-player';
import './router/http' // 引入axios
import './other/ivewImport' // ivew 模块引入   
import './other/plugin' // 所有引入的第三方插件
import directives from './directives/directives.js';
import { i18n } from './language/index' // 所有引入的第三方插件


import './assets/scss/index.scss'
import './assets/scss/my-theme/my-theme.less'
import moment from 'moment';
// 第三方
require('video.js/dist/video-js.css');
require('vue-video-player/src/custom-theme.css');
import myService from './assets/js/tool.js'; // 工具函数

Vue.config.productionTip = false
Vue.use(router)
Vue.use(VueCookies)
Vue.use(VideoPlayer);
Vue.use(myService);
Vue.prototype.$moment = moment;
Vue.prototype.$Bus = new Vue;

// 添加时间过滤器 引入moment.js
Vue.filter('moment', function (dataStr, pattern = 'YYYY/MM/DD HH:mm') {
  return moment(dataStr).format(pattern);
})
new Vue({
  router,
  i18n,
  store,
  render: h => h(App)
}).$mount('#app')