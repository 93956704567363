import axios from 'axios';
import config from '@assets/config.json'; // api 请求json 
import qs from "qs";
const state = {
  theme: '',
}
const mutations = {
  setTheme(state, data) {
    state.theme = data;
  },
}
const getters = {
  getTheme: state => {
    return state.theme;
  },
}
const actions = {
  // 判断是否有邮箱账号
  hasEmail({
    rootState,
    commit
  }, email) {
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.auth}/api/v1/account/has/email?email=${email}`, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        resolve(false);
        console.log(err);
      });
    });
  },
  // 登录
  login({
    rootState,
    commit
  }, opt) {
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.auth}/connect/token`, qs.stringify(opt), rootState.formHeaders).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        resolve(false);
        console.log(err);
      });
    });
  },
  // 发送邮箱验证码
  sendEamilCode({
    rootState
  }, opt) {
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.api}/api/v1/verificationcode/email?email=${opt.email}`).then(data => {
        if (data.data && data.status === 200) {
          resolve(data.data);
        } else {
          resolve([]);
        }
      }).catch(err => {
        resolve(false);
        console.log(err)
      });
    });
  },
  putEmail({
    rootState
  }, opt) { // 修改用户邮箱
    return new Promise((resolve, reject) => {
      axios.put(`${config.api.api}/api/v3/mmeberv3/update/email`, opt, rootState.header)
        .then(({
          data
        }) => {
          resolve(data);
        });
    }).catch(err => {
      console.log(err);
    })
  },
  changPSW({
    rootState,
    commit
  }, body) { // 修改密码
    return new Promise((resolve, reject) => {
      axios.put(`${config.api.auth}/api/v1/account/update/password/email`, body).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        resolve(false);
        console.log(err);
      });
    });
  },
  // 发送邮箱验证码
  sendEamilCode({
    rootState
  }, opt) {
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.api}/api/v1/verificationcode/email?email=${opt.email}`).then(data => {
        if (data.data && data.status === 200) {
          resolve(data.data);
        } else {
          resolve([]);
        }
      }).catch(err => {
        resolve(false);
        console.log(err)
      });
    });
  },
  putEmail({
    rootState
  }, opt) { // 修改用户邮箱
    return new Promise((resolve, reject) => {
      axios.put(`${config.api.api}/api/v3/mmeberv3/update/email`, opt, rootState.header)
        .then(({
          data
        }) => {
          resolve(data);
        });
    }).catch(err => {
      console.log(err);
    })
  },
  //mice cad 阿波罗接口（展商秀接口）
  graphqlPostByZXS({
    rootState
  }, opt) { // 获取主办
    let header = {
      "content-Type": "application/json; charset=utf-8;"
    }
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/graphql`, opt, header).then(data => {
        if (data.data) {
          resolve(data.data);
        } else {
          resolve(null);
        }
      }).catch((err) => {
        resolve(null);
        console.log(err);
      });
    });
  },
  // 注册
  addReg({
    rootState,
    commit
  }, opt) {
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/mmeberv3/reg/visitor`, opt, rootState.header).then(data => {
        if (data.data) {
          if (data.data.message === "电子邮箱无效") {
            data.data.message = 'The email address is not valid';
          }
          if (data.data.message === "密码无效") {
            data.data.message = 'The password is not valid';
          }
          if (data.data.message === "注册失败，电子邮箱已注册") {
            data.data.message = 'Registration failed and the email address was registered';
          }
          if (data.data.message === "创建账号失败") {
            data.data.message = 'The creation of an account failed';
          }
          resolve(data.data);
        }
      }).catch((err) => {
        resolve(false);
        console.log(err);
      });
    });
  },
  //注册新的用户
  addnewReg({
    rootState,
    commit
  }, opt) {
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/mmeberv3/reg/visitor/account`, opt, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        resolve(false);
        console.log(err);
      });
    });
  },
  // 注册
  editReg({
    rootState,
    commit
  }, opt) {
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/mmeberv3/reg/visitor/account/email`, opt, rootState.header).then(data => {
        if (data.data) {
          if (data.data.message === "电子邮箱无效") {
            data.data.message = 'The email address is not valid';
          }
          if (data.data.message === "密码无效") {
            data.data.message = 'The password is not valid';
          }
          if (data.data.message === "注册失败，电子邮箱已注册") {
            data.data.message = 'Registration failed and the email address was registered';
          }
          if (data.data.message === "创建账号失败") {
            data.data.message = 'The creation of an account failed';
          }
          resolve(data.data);
        }
      }).catch((err) => {
        resolve(false);
        console.log(err);
      });
    });
  },
  editpassword({
    rootState,
    commit
  }, opt) {
    return new Promise((resolve, reject) => {
      axios.put(`${config.api.auth}/api/v1/account/update/password/email`, opt, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        resolve(false);
        console.log(err);
      });
    });
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}