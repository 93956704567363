import axios from 'axios';
import config from '@assets/config.json'; // api 请求json
import qs from "qs";

const state = {
  activityList: [], // 活动信息,
}
const mutations = {
  setActivity(state, data) {
    state.activityList = data;
  },
}
const getters = {
  getActivity: state => {
    return state.activityList;
  },
}
const actions = {
  getCountry({
    rootState,
    commit
  }, opt) { // 获取国家省份城市
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.api}/api/v1/country/countries`, opt, rootState.header).then(({
        data
      }) => {
        if (data) {
          resolve(data);
        };
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  getCountrySub({
    rootState,
    commit
  }, opt) { // 获取国家省份城市
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v1/country/find`, opt, rootState.header).then(({
        data
      }) => {
        if (data) {
          resolve(data);
        };
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
}