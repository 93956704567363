// ivew 模块引入
import Vue from 'vue';
import ViewUI from 'view-design';
import {
  Tag,
  Button,
  Table,
  Icon,
  Modal,
  Message,
  Row,
  Col,
  Layout,
  Content,
  Footer,
  Card,
  Menu,
  Tabs,
  TabPane,
  Dropdown,
  Page,
  Input,
  Radio,
  Checkbox,
  Switch,
  Select,
  Option,
  Form,
  Tooltip,
  BackTop,
  Notice,
  Spin,
  Timeline,
  DatePicker,
  Rate,
  FormItem,
  TimelineItem,
  Collapse,
  Steps,
  Step,
  Panel,
  Sider,
  MenuItem,
  LoadingBar,
  TimePicker,
  RadioGroup,
  CheckboxGroup,
  Upload,
  Poptip,
  DropdownMenu,
  DropdownItem,
  Breadcrumb,
  InputNumber,
  Submenu,
  Slider,
  BreadcrumbItem,
  Time,
  ColorPicker,
  Carousel,
  CarouselItem,
  Badge,
  Cascader,
  Affix,
  locale,
} from 'view-design';
// import lang from 'view-design/dist/locale/en-US';
const lang={
    i: {
    locale: 'en-US',
    select: {
        placeholder: 'Select',
        noMatch: 'No matching data',
        loading: 'Loading'
    },
    table: {
        noDataText: 'No Data',
        noFilteredDataText: 'No filter data',
        confirmFilter: 'Confirm',
        resetFilter: 'Reset',
        clearFilter: 'All',
        sumText: 'Sum'
    },
    datepicker: {
        selectDate: 'Select date',
        selectTime: 'Select time',
        startTime: 'Start Time',
        endTime: 'End Time',
        clear: 'Clear',
        ok: 'OK',
        datePanelLabel: '[mmmm] [yyyy]',
        month: 'Month',
        month1: 'January',
        month2: 'February',
        month3: 'March',
        month4: 'April',
        month5: 'May',
        month6: 'June',
        month7: 'July',
        month8: 'August',
        month9: 'September',
        month10: 'October',
        month11: 'November',
        month12: 'December',
        year: 'Year',
        weekStartDay: '0',
        weeks: {
            sun: 'Sun',
            mon: 'Mon',
            tue: 'Tue',
            wed: 'Wed',
            thu: 'Thu',
            fri: 'Fri',
            sat: 'Sat'
        },
        months: {
            m1: 'Jan',
            m2: 'Feb',
            m3: 'Mar',
            m4: 'Apr',
            m5: 'May',
            m6: 'Jun',
            m7: 'Jul',
            m8: 'Aug',
            m9: 'Sep',
            m10: 'Oct',
            m11: 'Nov',
            m12: 'Dec'
        }
    },
    transfer: {
        titles: {
            source: 'Source',
            target: 'Target'
        },
        filterPlaceholder: 'Search here',
        notFoundText: 'Not Found'
    },
    modal: {
        okText: 'OK',
        cancelText: 'Cancel'
    },
    poptip: {
        okText: 'OK',
        cancelText: 'Cancel'
    },
    page: {
        prev: 'Previous Page',
        next: 'Next Page',
        total: 'Total',
        item: 'item',
        items: 'items',
        prev5: 'Previous 5 Pages',
        next5: 'Next 5 Pages',
        page: '/page',
        goto: 'Page',
        p: ''
    },
    rate: {
        star: 'Star',
        stars: 'Stars'
    },
    time: {
        before: ' ago',
        after: ' after',
        just: 'just now',
        seconds: ' seconds',
        minutes: ' minutes',
        hours: ' hours',
        days: ' days'
    },
    tree: {
        emptyText: 'No Data'
    }
  }
}
locale(lang);
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);


Vue.component('Tag', Tag)
Vue.component('Button', Button)
Vue.component('Table', Table)
Vue.component('Row', Row)
Vue.component('Icon', Icon)
Vue.component('Col', Col)
Vue.component('Layout', Layout)
Vue.component('Card', Card)
Vue.component('Menu', Menu)
Vue.component('Tabs', Tabs)
Vue.component('TabPane', TabPane)
Vue.component('Dropdown', Dropdown)
Vue.component('Page', Page)
Vue.component('Input', Input)
Vue.component('Radio', Radio)
Vue.component('Checkbox', Checkbox)
Vue.component('Select', Select)
Vue.component('Option', Option)
Vue.component('DatePicker', DatePicker)
Vue.component('Rate', Rate)
Vue.component('Form', Form)
Vue.component('FormItem', FormItem)
Vue.component('Tooltip', Tooltip)
Vue.component('BackTop', BackTop)
Vue.component('Spin', Spin)
Vue.component('Timeline', Timeline)
Vue.component('TimelineItem', TimelineItem)
Vue.component('Collapse', Collapse)
Vue.component('Steps', Steps)
Vue.component('Step', Step)
Vue.component('Panel', Panel)
Vue.component('Sider', Sider)
Vue.component('Content', Content)
Vue.component('Footer', Footer)
Vue.component('MenuItem', MenuItem)
Vue.component('LoadingBar', LoadingBar)
Vue.component('DropdownMenu', DropdownMenu)
Vue.component('DropdownItem', DropdownItem)
Vue.component('RadioGroup', RadioGroup)
Vue.component('CheckboxGroup', CheckboxGroup)
Vue.component('Upload', Upload)
Vue.component('Poptip', Poptip)
Vue.component('TimePicker', TimePicker)
Vue.component('Breadcrumb', Breadcrumb)
Vue.component('InputNumber', InputNumber)
Vue.component('BreadcrumbItem', BreadcrumbItem)
Vue.component('Time', Time)
Vue.component('Submenu', Submenu)
Vue.component('Slider', Slider)
Vue.component('ColorPicker', ColorPicker)
Vue.component('Carousel', Carousel)
Vue.component('CarouselItem', CarouselItem)
Vue.component('Badge', Badge)
Vue.component('Cascader', Cascader)
Vue.component('Affix', Affix)


//不能和html标签重复的组件，添加别名(除了Switch、Circle在使用中必须是iSwitch、iCircle,其他都可以不加"i")
Vue.component('iSwitch', Switch)
Vue.component('iModal', Modal)


Vue.prototype.$Message = Message
Vue.prototype.$Notice = Notice
Vue.prototype.$Modal = Modal
Vue.prototype.$Loading = LoadingBar;