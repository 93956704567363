import Vue from 'vue';

const loadImg = Vue.directive('load', {
    bind: function (el, binding) {
        if (el.src) {
            el.src += `?x-oss-process=style/${binding.value}`;
        }
    },
    update: function (el, binding, vnode) {
        if (el.src && el.src.search(/\?x-oss-process/) === -1) {
            el.src += `?x-oss-process=style/${binding.value}`;
        }
    }
})
const watermark = Vue.directive('watermark', {
    bind: function (el, binding, vnode) {
        if (el && el.getAttribute('large')) {
            const large = el.getAttribute('large');
            el.setAttribute('large', large + `?x-oss-process=style/${binding.value}`);
        }
    },
})
export {loadImg, watermark}
