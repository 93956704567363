// {{ $t('lang.home')}}
export const lang = {
  View_on_phone: '手机版',
  Register: '参观预登记',
  Home: '首页',
  Demand: '需求',
  Exhibitor: '展商',
  Product: '展品',
  Activity: '活动',
  Floorplan: '导览',
  News: '资讯',
  Supply: '供需',
  About: '关于', 
  Message: '消息',
  Search: '搜索',
  Official: '官方在线',
  Exhibitor_login: '展商登录',
  Overview: '展会概况',
  Register2: '注册参观',
  Register3: '免费注册',
  Official_website: '官网链接',
  Floorplan2: '展位导览',
  Create_a_Supply_Demand: '免费发布供需',
  Purchase_products2: '供应信息',
  Supply_products2: '采购信息',
  Purchase_products: '采购产品',
  Supply_products: '供应产品',
  Please_add_product_name: '请添加你想产品名称',
  Please_add_a_brief_description: '请详细描述产品信息',
  Upload: '上传',
  Submit: '发布供需',
  Category: '展品类别',
  Concurrent_Exhibition: '其他展区',
  Visitor: '访客',
  Brand: '品牌展商',
  HotProduct: '热搜产品',
  HotProduct2: '热门展品',
  HotActivity: '热门活动',
  Popular2: '为您推荐',
  Ativity: '同期活动',
  Moments: '精彩视频',
  News: '资讯头条',
  Web: 'Website',
  Share: '分享',
  Contact: '联系我们',
  Top: '回到顶部',
  More: '更多',
  Failed_to_get_ticket_information: '获取证件失败',
  Please_add_your_suggestions_or_feedback: '添加反馈内容',
  Successfully_submitted: '成功提交',
  failed_to_submit: '提交失败',
  The_picture_format_is_not_correct: '图片格式不对',
  Please_upload_pictures_within_4_megabytes: '图片上传不超过4M',
  pictures_at_most: '至多5张',
  failed_to_upload: '上传失败',
  You_have_already_registered: '你已经注册过了',
  The_function_is_not_open: '暂未开放',
  Its_the_end: '到底了',
  No_data: 'No data',
  Some_exhibitors_have_been_hidden: '已隐藏展商',
  Please_select_a_type: '请选择一个类型',
  Please_add_a_brief_description2: '请添加内容',
  Youve_already_liked_it: '点赞过了 ',
  sign_in: '登录',
  Scan_QR_code: '扫描二维码',
  The_exhibition_is_over: '展会已结束',
  Homepage: '返回首页',
  Personal_Center: '个人中心',
  Supply_Demand: '我的供需',
  Inquiry_records: '我的询盘',
  loading: '加载中...',
  loadMore: '加载更多',
  look_More: '查看更多',
  Hot: '热门',
  Visitors: '在线观众',
  Interaction: '互动交流',
  send: '发送',
  Company_information: '公司信息',
  Scan_code_to_view_mobile_terminal: '扫码查看移动端',
  end: '已结束',
  activity_Info: '活动详情',
  date_Info: '日程安排',
  Live_pictures: '图片直播',
  Follow: '关注',
  Followed: '已关注',
  Talkcount: '洽谈',
  Appointment: '预约',
  Appoint: '预约',
  Appoint: '在线洽谈',
  Follows: '人关注',
  Likes: '人点赞',
  Detail: '展商简介',
  Addr: '地址',
  Hot_News: '热点资讯', 
  Your_account: '个人中心',
  Ticket: '我的证件',
  Business: '我的预约',
  Supply_Demand: '我的询盘',
  Inquiry_records: '我的供需',
  Following: '我的关注',
  Notification: '我的消息', 
  Contact_us: '联系我们',
  Contact_host: '联系我们',
  Feedback: '意见反馈',
  Account_Settings: '账户管理',
  Profile: '我的信息',
  Bind_other_accounts: '绑定授权',
  Setting: '修改密码', 
  Mine: '我发布的',
  Following: '我关注的', 
  Modify: '修改信息',
}