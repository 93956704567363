// {{ $t('lang.home')}}
export const lang = {
    View_on_phone: 'View on phone',
    Register: 'Register',
    Home: 'Home',
    Demand: 'Demand',
    Exhibitor: 'Exhibitor',
    Product: 'Product',
    Activity: 'Activity',
    Floorplan: 'Floorplan',
    News: 'News',
    Supply: 'Supply',
    About: 'About',
    Message: 'Message',
    Search: 'Search',
    Official: 'Official',
    Exhibitor_login: 'Exhibitor login',
    Overview: 'Overview',
    Register2: 'Register',
    Register3: 'Register',
    Official_website: 'Show website',
    Floorplan2: 'Floorplan',
    Create_a_Supply_Demand: 'Post a Supply & Demand',
    Purchase_products2: 'Purchase products',
    Supply_products2: 'Supply products',
    Purchase_products: 'Purchase products',
    Supply_products: 'Supply products',
    Please_add_product_name: 'Please enter product name',
    Please_add_a_brief_description: 'Please describe your detailed requirement',
    Upload: 'Upload',
    Submit: 'Submit',
    Category: 'Category',
    Concurrent_Exhibition: 'Concurrent Exhibition',
    Visitor: 'Visitor',
    Brand: 'Brand',
    HotProduct: 'Popular',
    HotProduct2: 'Product',
    HotActivity: 'Activity',
    Popular: 'Popular',
    Popular2: 'Product',
    Ativity: 'Ativity',
    Moments: 'Moments',
    News: 'News',
    Web: 'Web',
    Share: 'Share',
    Contact: 'Contact',
    Top: 'Top',
    More: 'More',
    Failed_to_get_ticket_information: "Failed to get ticket information",
    Please_add_your_suggestions_or_feedback: "Please add your suggestions or feedback",
    Successfully_submitted: "Successfully submitted",
    failed_to_submit: "failed to submit",
    The_picture_format_is_not_correct: "The picture format is not correct",
    Please_upload_pictures_within_4_megabytes: "Please upload pictures within 4 megabytes",
    pictures_at_most: "5 pictures at most",
    failed_to_upload: "failed to upload",
    You_have_already_registered: "You have already registered",
    The_function_is_not_open: "The function is not open",
    Its_the_end: "It's the end",
    No_data: "No data",
    Some_exhibitors_have_been_hidden: "Some exhibitors have been hidden",
    Please_select_a_type: "Please select a type",
    Please_add_a_brief_description2: "Please add a brief description",
    Youve_already_liked_it: "You've already liked it",
    sign_in: "sign in",
    Scan_QR_code: "Scan QR code",
    The_exhibition_is_over: "The exhibition is over",
    Homepage: "Homepage",
    Personal_Center: "Personal Center",
    Supply_Demand: "Supply & Demand",
    Inquiry_records: "Inquiry records ",
    loading: "loading...",
    loadMore: 'load more',
    look_More: 'look More',
    Hot: 'hot',
    Visitors: 'Visitors',
    Interaction: 'Chat',
    send: 'send',
    Company_information: 'Company information',
    Scan_code_to_view_mobile_terminal: 'scan QR code to view the mobile version',
    end: 'end',
    activity_Info: 'Introduction',
    date_Info: 'Schedule',
    Live_pictures: 'Live pictures',
    Follow: 'Follow',
    Followed: 'Followed',
    Talkcount: 'Talkcount',
    Appointment: 'Appointment',
    Appoint: 'Appoint',
    Follows: 'Follow(s)',
    Likes: 'Like(s)',
    Detail: 'Detail',
    Addr: 'Addr',
    Hot_News: 'Hot News',
    Your_account: 'Your account',
    Ticket: 'My Ticket',
    Business: 'Business',
    Supply_Demand: 'Supply & Demand',
    Inquiry_records: 'Inquiry records',
    Following: 'Following',
    Notification: 'Notification',
    Contact_us: 'Contact us',
    Contact_host: 'Contact host',
    Feedback: 'Feedback',
    Account_Settings: 'Account Settings',
    Profile: 'Profile',
    Bind_other_accounts: 'Bind other accounts',
    Setting: 'Setting',
    Mine: 'Mine',
    Modify: 'Modify',
    page:{
        goto:'page'
    }
}