/* eslint-disable */
import axios from 'axios';
import config from '@assets/config.json'; // api 请求json
import qs from "qs";
import {
  Message,
  Modal
} from 'view-design';
import router from "@/router/router.js";

// 通用 Module 
const state = {
  company: null, // 公司信息
  exhibition: null, // 展会信息
  joinInfo: null, // 参展信息,提会刊时用的joinId
}
const mutations = {
  setCompany(state, data) {
    state.company = data;
  },
  setExhibition(state, data) {
    state.exhibition = data;
  },
  setJoinInfo(state, data) {
    state.joinInfo = data;
  },
}
const getters = {
  getCompany: state => {
    return state.company;
  },
  getExhibition: state => {
    return state.exhibition;
  },
  getJoinInfo: state => {
    return state.joinInfo;
  },
}
const actions = {
  addView({
    rootState
  }, req) { // 添加浏览器记录 浏览目标：exhibition：展会、exhibitor：展商、product：展品、activity：活动、supplyDemand：供需、theme：话题、talk：讨论、articles：文章
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/addview`, req, {
        headers: {
          'Content-Type': 'application/json;charset=UTF-8'
        }
      }).then(data => {
        if (data.data) {
          resolve(data.data);
        };
      }).catch((err) => {
        console.log(err);
      });
    });
  },
  checkUserName({
    rootState
  }, req) { // 检查是否有注册用户
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.auth}/api/Account/CheckUserName`, req, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        };
      }).catch((err) => {
        console.log(err);
      });
    });
  },

  getMsgCode({}, phone) { // 获取短信验证码
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.reqApi}/api/VerCode/sms/send`, {
        Phone: phone
      }).then(data => {
        if (data.data) {
          if (data.data.successed) {
            Message.success('验证码已发送');
          } else {
            Message.success('验证码发送失败');
          }
          resolve(data.data);
        };
      }).catch((err) => {
        console.log(err);
      });
    });
  },

  validateMsgCode({}, opt) { // 效验短信验证码
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.reqApi}/api/VerCode/sms/validate`, opt).then(data => {
        if (data.data) {
          resolve(data.data);
        };
      }).catch((err) => {
        console.log(err);
      });
    });
  },
  getImgCode({}) { // 获取图片验证码
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.api}/api/v1/verificationcode/image`).then(data => {
        if (data.data) {
          resolve(data.data);
        };
      }).catch((err) => {
        console.log(err);
      });
    });
  },
  validateImgCode({}, opt) { // 效验图片验证码
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.api}/api/v1/verificationcode/valid?key=${opt.Key}&code=${opt.Code}`).then(data => {
        if (data && data.status === 200) {
          resolve(data.data);
        };
      }).catch((err) => {
        console.log(err);
      });
    });
  },
  getJoinInfo({
    rootState
  }, opt) { // 判断是否参展
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.reqApi}/api/join/GetJoin`, opt, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        };
      }).catch((err) => {
        console.log(err);
      });
    });
  },
  getHomeMsg({
    rootState
  }, id) { // 判断是否参展
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.reqApi}/api/company/GetCompanyCount?joinid=${id}`, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        };
      }).catch((err) => {
        console.log(err);
      });
    });
  },
  getExToken({
    rootState,
    commit
  }, opt) { // 展商获取token
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v1/account/login/exhibitor`, opt).then(data => {
        if (data.data) {
          commit('setAuth', data.data.data.tokenInfo, {
            root: true
          });
          commit('setUserId', data.data.data.userId, {
            root: true
          });
          commit('setExhibitorId', data.data.data.exhibitorId, {
            root: true
          });
          resolve(data.data);
        }
      }).catch((err) => {
        let req = err.response;
        if (req && req.data && req.data.error_description) {
          if (req.data.code === 4002) {
            Message.error({
              content: '登录身份有误，请重新确认',
              duration: 2
            });
          } else {
            Message.error({
              content: req.data.error_description,
              duration: 2
            });
          }
        }
        resolve(null);
      });
    });
  },
  getExTokenByPhone({
    rootState,
    commit
  }, opt) { // 展商手机号获取token
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v3/account/login/exhibitor/phonenumber`, opt).then(data => {
        if (data.data) {
          console.log(data.data, '手机号获取token')
          commit('setAuth', data.data.data.tokenInfo, {
            root: true
          });
          // commit('setUserId', data.data.data.userId, { root: true });
          commit('setExhibitorId', data.data.data.exhibitorId, {
            root: true
          });
          resolve(data.data);
        }
      }).catch((err) => {
        let req = err.response;
        if (req && req.data && req.data.error_description) {
          if (req.data.code === 4002) {
            Message.error({
              content: '登录身份有误，请重新确认',
              duration: 2
            });
          } else {
            Message.error({
              content: req.data.error_description,
              duration: 2
            });
          }
        }
        resolve(null);
      });
    });
  },
  getHostToken({
    rootState,
    commit
  }, opt) { // 主办获取token
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v1/account/login/host`, opt).then(data => {
        if (data.data) {
          commit('setAuth', data.data.data.tokenInfo, {
            root: true
          });
          commit('setHost', data.data.data, {
            root: true
          });
          commit('setUser', data.data.data.tokenInfo.userInfo, {
            root: true
          });
          commit('setUserId', data.data.data.userId, {
            root: true
          });
          commit('setHostId', data.data.data.id, {
            root: true
          });
          resolve(data.data);
        };
      }).catch((err) => {
        let req = err.response;
        if (req && req.data && req.data.error_description) {
          if (req.data.code === 4002) {
            Message.error({
              content: '登录身份有误，请重新确认',
              duration: 2
            });
          } else {
            Message.error({
              content: req.data.error_description,
              duration: 2
            });
          }
        }
        resolve(null);
      });
    });
  },
  getCompany({
    rootState
  }, id) { // 根据用户id获取公司信息 
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.reqApi}/api/company/GetByUserId?userId=${id}`, rootState.header).then(data => {
        resolve(data.data);
      });
    });
  },
  getCompanyById({
    rootState
  }, id) { // 根据用户id获取公司信息 
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.reqApi}/api/company?id=${id}`, rootState.header).then(data => {
        resolve(data.data);
      });
    });
  },
  getExIdByUserId({
    rootState
  }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.reqApi}/api/Join/GetJoinRelevant?userid=${id}`, rootState.header).then(data => {
        resolve(data.data);
      });
    });
  },
  exit({
    commit,
    rootState
  }) {
    let url = '';
    if (rootState.role === 1) {
      url = "/live/login/alogin";
    } else if (rootState.role === 2) {
      url = "/admin/login/alogin";
    } else {
      url = "/ex-live/login/ex?id=" + rootState.exhibitionId;
    }
    commit('setLogin', false, {
      root: true
    });
    commit('setUser', null, {
      root: true
    });
    commit('setAuth', null, {
      root: true
    });
    commit('setToken', null, {
      root: true
    });
    localStorage.clear();
    location.href = url;
  },
  changePasswordByOld({
    rootState
  }, opt) { // 通过原密码改新密码 
    return new Promise((resolve, reject) => {
      axios.put(`${config.api.auth}/api/account/resetpassword`, opt, rootState.header).then(data => {
        resolve(data.data);
      });
    });
  },
  // 公司认证信息 
  getCompLicense({
    rootState,
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.reqApi}/api/CompLicense/GetByCompId?compId=${id}`, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        } else {
          resolve(null);
        }
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  addCompLicense({
    rootState
  }, opt) { // 添加认证信息
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.reqApi}/api/CompLicense`, opt, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  editCompLicenseAuditStatus({
    rootState
  }, id) { // 修改认证信息的审核状态
    return new Promise((resolve, reject) => {
      axios.delete(`${config.api.reqApi}/api/CompLicense/SetAuditStatus`, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  editCompLicense({
    rootState
  }, opt) { // 修改认证信息
    return new Promise((resolve, reject) => {
      axios.put(`${config.api.reqApi}/api/CompLicense`, opt, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },

  // 公司发票信息
  getCompInvoice({
    rootState,
    commit
  }, id) {
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.reqApi}/api/CompInvoice/GetByCompId?compId=${id}`, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        } else {
          resolve(null);
        }
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  addCompInvoice({
    rootState
  }, opt) { // 添加认证信息
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.reqApi}/api/CompInvoice`, opt, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  editCompInvoice({
    rootState
  }, opt) { // 修改认证信息
    return new Promise((resolve, reject) => {
      axios.put(`${config.api.reqApi}/api/CompInvoice`, opt, rootState.header).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  getExhibitionInfo({
    rootState,
    dispatch,
    commit
  }, id) { // 获取当前展会信息 
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.api}/api/v1/exhibition?id=${id}`, rootState.header).then(data => {
        if (data.data) {
          if (data.data.result) {
            commit("setExhibitionInfo", data.data.data, {
              root: true
            });
            dispatch('getExhibitionConfig');
          }
          resolve(data.data);
        }
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },
  async getExhibitionConfig({
    rootState,
    rootGetters,
    dispatch,
    commit
  }) { // 获取当前展会配置
    let query = `{
        settingsQuery {
          exhibitionConfig(exhibitionId: "${rootGetters.getExhibitionId}") {
              display {
                themeId
                boothLink
                enabledExhibitorLimitNumber
                exhibitorLimitNumber
                exhibitorWapLogo
                exhibitorWebLogo
                enabledCustomQuick
                pcShowExhibitionViewCount
                pcShowExhibitionUserCount
                homeActivity {
                  col
                  row
                }
                homeExhibitorLogo { 
                  col
                  row
                }
                showExhibitorCount
                showViewCount
              }
              enabledAuditAppointment
              enabledAutoAuditActivity
              enabledAutoAuditExhibitor
              enabledAutoAuditLive
              enabledAutoAuditNewEnquiry
              enabledAutoAuditProduct
              enabledAutoAuditSample
              enabledAutoAuditSupplyDemand
              enabledAutoAuditTheme
              feature {
                enabledArticle
                enabledExhibitionList
                enabledBmm
                enabledBrandExhibitor
                enabledConcurrentActivity
                enabledContacts
                enabledDataStatistics
                enabledLive
                enabledNearbyExhibitor
                enabledRecommendForYou
                enabledSpecialLive
                enabledSupplyDemand
                enabledSwitchLanguage
                enabledActivityReg
                enabledExhibitionSurvey
                enabledExhibitionVideo
                enabledExhibitionTalk
                enabledExhibitorApply
                exhibitorApplyUrl
              }
              invitation {
                backgroundColor
                backgroundUrl
                topToContent
              }
              keepRecord  
              sayHello {
                dayFromNumber
                dayToNumber
                enabledToRepeat
              }
              settingName
              settingScope
              settingsDisplayName
              settingType
              visitorReg {
                notice
                noticeEn
                regLink
                regSuccessedNotice
                regSuccessedNoticeEn
              }
          }
        }
      }
        `;
    let body = {
      query: query,
    };
    let data = await dispatch('graphqlPostByZXS', body, {
      root: true
    });
    return new Promise((resolve, reject) => {
      if (data.data) {
        if (data.data.settingsQuery && data.data.settingsQuery.exhibitionConfig) {
          commit("setExhibitionConfig", data.data.settingsQuery.exhibitionConfig, {
            root: true
          });
          dispatch('theme/setTheme', {}, {
            root: true
          });
          resolve(data.data.settingsQuery.exhibitionConfig);
        }
      } else {
        resolve(false);
      }
    })
  },
  async getExhibitionInfoByGraphal({
    rootState,
    dispatch,
    commit
  }, id) { // 获取当前展会信息
    let query = ` {
          exhibitionQuery{
            get(id:"${id}"){
              activityCount
              address
              addressEn
              appointmentCount
              banner 
              city
              commentCount
              coordinate
              country
              createAt
              customerServiceCount
              deleteAt
              endDate
              exhibitorCount
              followCount
              hallName
              hallPanorama
              helpMoney 
              hostId 
              industry
              isDeleted 
              liveCount
              liveEnabledLive
              liveEnabledSubtitles
              liveEnabledTranslate
              liveTranslateLangs
              logo
              id
              name
              nameEn
              organization
              organizationEn
              productCount
              province
              remark
              remarkEn
              shareCount
              shortName
              shortNameEn
              startData
              talkCount
              updateAt
              userCount
              video
              viewCount
              website
              ico
            }
          }
        }
        `;
    let body = {
      query: query,
    };
    let data = await dispatch('graphqlPostByZXS', body, {
      root: true
    });
    return new Promise((resolve, reject) => {
      if (data.data) {
        if (data.data.exhibitionQuery && data.data.exhibitionQuery.get) {
          commit("setExhibitionInfo", data.data.exhibitionQuery.get, {
            root: true
          });
          dispatch('getExhibitionConfig');
          resolve(data.data.exhibitionQuery.get);
        }
      } else {
        resolve(false);
      }
    })
  },
  async getExhibitionInfoByHost({ // 通过主办id查询当前展会下的信息
    rootState,
    dispatch,
    commit
  }, id) { // 获取当前展会信息
    let query = ` {
          exhibitionQuery{
            get(id:"${id}"){
              activityCount
              address
              appointmentCount
              banner 
              city
              commentCount
              coordinate
              country
              createAt
              customerServiceCount
              deleteAt
              endDate
              exhibitorCount
              followCount
              hallName
              hallPanorama
              helpMoney 
              hostId 
              industry
              isDeleted 
              liveCount
              liveEnabledLive
              liveEnabledSubtitles
              liveEnabledTranslate
              liveTranslateLangs
              logo
              id
              name
              nameEn
              organization
              organizationEn
              productCount
              province
              remark
              remarkEn
              shareCount
              shortName
              shortNameEn
              startData
              talkCount
              updateAt
              userCount
              video
              viewCount
              website
            }
          }
        }
        `;
    let body = {
      query: query,
    };
    let data = await dispatch('graphqlPostByZXS', body, {
      root: true
    });
    return new Promise((resolve, reject) => {
      if (data.data) {
        if (data.data.exhibitionQuery && data.data.exhibitionQuery.get) {
          commit("setExhibitionInfo", data.data.exhibitionQuery.get, {
            root: true
          });
          resolve(data.data.exhibitionQuery.get);
        }
      } else {
        resolve(false);
      }
    })
  },

  // 展商登录页获取展会列表
  getExListFirst({
    rootState,
    commit
  }, opt) {
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v1/exhibition/query`, opt).then(data => {
        if (data.data) {
          resolve(data.data);
        }
      }).catch((err) => {
        console.log(err.response.data);
      });
    });
  },

  getToken({
    rootState,
    commit
  }, opt) { // 获取token
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.auth}/connect/token`, qs.stringify(opt)).then(data => {
        if (data.data) {
          resolve(data.data);
        };
      }).catch((err) => {
        console.log(err);
      });
    });
  },
  getAdminToken({ // 运维登录
    rootState,
    commit
  }, opt) { // 获取token 
    return new Promise((resolve, reject) => {
      axios.post(`${config.api.api}/api/v1/account/login/admin`, opt).then(({
        data
      }) => {
        if (data.data) {
          commit('setAuth', data.data, {
            root: true
          });
          resolve(data.data);
        } else {
          resolve(data);
        }
      }).catch((err) => {
        console.log(err);
      });
    });
  },

  validateCode({}, opt) { // 效验短信验证码
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.api}/api/v1/verificationcode/valid?key=${opt.key}&code=${opt.code}`).then(data => {
        resolve(data.data);
      }).catch((err) => {
        console.log(err);
      });
    });
  },
  validatePhone({}, phone) { // 效验手机号是否存在
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.auth}/api/v1/account/has/phonenumber?phoneNumber=${phone}`).then(data => {
        resolve(data.data);
      }).catch((err) => {
        console.log(err);
      });
    });
  },
  validateEmail({}, email) { // 效验手机号是否存在
    return new Promise((resolve, reject) => {
      axios.get(`${config.api.auth}/api/v1/account/has/email?email=${email}`).then(data => {
        resolve(data.data);
      }).catch((err) => {
        console.log(err);
      });
    });
  },
}
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,

}