import axios from 'axios';
import config from '@assets/config.json'; // api 请求json 

const state = {}
const mutations = {}
const getters = {}
const actions = {
    async getBannerByGraphal({
        rootState,
        dispatch
    }, type) { 
        let query = ` {
            bannerQuery {
                by(
                    exhibitionId: "${rootState.exhibitionId}"
                    position: "${type}"
                ) {
                    name
                    nameEn
                    content
                    covers
                    enabledCountdown
                    endAt
                    exhibitionId
                    position
                    showIndex
                    url
                } 
            } 
        }
        `; 
        let body = {
            query: query,
        };
        let data = await dispatch('graphqlPostByZXS', body, {
            root: true
        });
        return new Promise((resolve, reject) => { 
            if (data.data) {
                resolve(data.data);
            } else {
                resolve(false);
            }
        })
    },
    getBanner({
        rootState,
        commit
    }, opt) {
        // 获取活动信息
        // show.page.home 展商秀首页  
        // show.page.exhibitors 展商秀展商列表首页
        // show.page.products 展商秀展品首页
        // show.page.home.pc PC 展商秀首页
        // show.page.exhibitors.pc PC 展商秀展商列表首页
        // show.page.products.pc PC 展商秀展品首页
        // show.page.home.ad.pc PC 展商秀管广告
        return new Promise((resolve, reject) => {
            axios.post(`${config.api.liveApi}/api/v3/banner/query`, opt, rootState.header).then(data => {
                if (data.data) {
                    resolve(data.data);
                } else {
                    resolve(null);
                }
            }).catch((err) => {
                console.log(err.response.data);
            });
        });
    },
    addBanner({
        rootState
    }, opt) { // 添加活动信息
        return new Promise((resolve, reject) => {
            axios.post(`${config.api.liveApi}/api/v3/banner`, opt, rootState.header).then(data => {
                if (data.data) {
                    resolve(data.data);
                }
            }).catch((err) => {
                console.log(err.response.data);
            });
        });
    },
    delBanner({
        rootState
    }, id) { // 删除活动信息
        return new Promise((resolve, reject) => {
            axios.delete(`${config.api.liveApi}/api/v3/banner?id=${id}`, rootState.header).then(data => {
                if (data.data) {
                    resolve(data.data);
                }
            }).catch((err) => {
                console.log(err.response.data);
            });
        });
    },
    editBanner({
        rootState
    }, opt) { // 修改活动信息
        return new Promise((resolve, reject) => {
            axios.patch(`${config.api.liveApi}/api/v3/banner?id=${opt.id}`, opt.opt, rootState.header).then(data => {
                if (data.data) {
                    resolve(data.data);
                }
            }).catch((err) => {
                console.log(err.response.data);
            });
        });
    },

}
export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}