const myService = function (Vue, options) {
  Vue.prototype.$service = {
    /**
     * 计算开始的时间在当前时间的描述  4分钟前 1小时前 ...
     * @param {Date} originDate 开始时间
     * @return {string} 该时间在当前时间的描述
     */
    SmarDate(originDate) {
      let nowDate = new Date(),
        isToday = false,
        isYesterday = false,
        isPastYear = false,
        isPreviousYear = false,
        originYear,
        originMonth,
        originDay,
        diffMinute,
        formatedDate;

      diffMinute = Math.round((nowDate.getTime() - originDate.getTime()) / (1000 * 60));

      originYear = originDate.getFullYear();
      originMonth = originDate.getMonth() + 1;
      originDay = originDate.getDate();

      if (diffMinute <= nowDate.getHours() * 60) {
        isToday = true;
      }
      if (nowDate.getDate() - originDay == 1) {
        isYesterday = true;
      }
      if (nowDate.getFullYear() - originYear == 1) {
        isPreviousYear = true;
      } else if (nowDate.getFullYear() - originYear > 1) {
        isPastYear = true;
      }
      if (diffMinute < 10 && isToday) {
        formatedDate = '刚刚';
      } else if (diffMinute < 60 && isToday) {
        formatedDate = `${diffMinute}分钟前`;
      } else if (diffMinute < 60 * 24 && isToday) {
        formatedDate = `${Math.floor(diffMinute / 60)}小时前`;
      } else if (diffMinute < 60 * 24 * 2 && isYesterday) {
        formatedDate = '1天前';
      } else if (diffMinute < 60 * 24 * 4) {
        formatedDate = '2天前';
      } else if (isPreviousYear) {
        formatedDate = '去年';
      } else if (isPastYear) {
        formatedDate = `${originYear}/`;
      } else {
        formatedDate = `${originMonth}/${originDay}`;
      }
      return formatedDate;
    },
    replaceSpeace(txt) {
      if (!txt) return;
      let txt1 = txt.replace(/\n/g, "<br/>");
      return txt1;
    },
    formatTime(date) {
      const year = date.getFullYear()
      const month = date.getMonth() + 1
      const day = date.getDate()

      const hour = date.getHours()
      const minute = date.getMinutes()
      const second = date.getSeconds()

      const t1 = [year, month, day].map(this.formatNumber).join('/')
      const t2 = [hour, minute, second].map(this.formatNumber).join(':')

      return `${t1} ${t2}`
    },
    formatNumber(n) {
      const str = n.toString()
      return str[1] ? str : `0${str}`
    }
  }
};

export default myService;

// 函数防抖(输入框)
export function debounce(fn, delay) {
  // 记录上一次的延时器
  var timer = null;
  var delay = delay || 200;
  return function () {
    var args = arguments;
    var that = this;
    // 清除上一次延时器
    clearTimeout(timer)
    timer = setTimeout(function () {
      fn.apply(that, args)
    }, delay);
  }
}