 let orgin = location.host.split('.').reverse()[1];
 let clientId = orgin === 'zhanshangxiu' ? 'synair-showonline-js' : `synair-${orgin}-js`;
 console.log('orgin:', orgin);
 console.log('clientId:', clientId);



 export const oidcSettings = {
     // 授权服务器地址
     authority: "https://auth.zhanshangxiu.com",
     // 客户端id
     clientId: clientId,
     // 授权回调地址(需提供)
     redirectUri: process.env.NODE_ENV === 'development' ? `${location.origin  + '/'}OidcCallback` : `${location.origin  + '/'}OidcCallback`,
     // redirectUri:`${location.origin  + '/'}/OidcCallback`,
     // 响应类型
     responseType: "code",
     // 作用域
     scope: "openid showonline-api profile",
     // 是否加载用户信息
     loadUserInfo: true,
     // 调起微信手机登录
     acrValues: "idp:Weixin",

     // silentRedirectUri: `${location.origin  + '/'}/silentSignInCallback`,

     automaticSilentRenew: true,

     postLogoutRedirectUri: `${location.origin  + '/'}home/index`,

     automaticSilentSignin: true, // If true vuex-oidc will try to silently signin unauthenticated users on public routes. Defaults to true

     extraQueryParams: {
         // 退出登录回调链接，会覆盖post_logout_redirect_uri，可选
         postLogoutRedirectUri: `${location.origin  + '/'}redirectUrl`
     }
 }
 export const oidcSettingsQQ = {
     // 授权服务器地址
     authority: "https://auth.zhanshangxiu.com",
     // 客户端id
     clientId: clientId,
     // 授权回调地址(需提供)
     redirectUri: process.env.NODE_ENV === 'development' ? `${location.origin  + '/'}OidcCallback` : `${location.origin  + '/'}OidcCallback`,
     // redirectUri:`${location.origin  + '/'}/OidcCallback`,
     // 响应类型
     responseType: "code",
     // 作用域
     scope: "openid showonline-api profile",
     // 是否加载用户信息
     loadUserInfo: true,
     // 调起微信手机登录
     acrValues: "idp:QQ",

     // silentRedirectUri: `${location.origin  + '/'}/silentSignInCallback`,

     automaticSilentRenew: true,

     postLogoutRedirectUri: `${location.origin  + '/'}home/index`,

     automaticSilentSignin: true, // If true vuex-oidc will try to silently signin unauthenticated users on public routes. Defaults to true
     extraQueryParams: {
         // 退出登录回调链接，会覆盖post_logout_redirect_uri，可选
         postLogoutRedirectUri: `${location.origin  + '/'}redirectUrl`
     },
 }

 // 1个小时token过期
 // authenticateOidc:  如果未登录，则使用重定向登录进行身份验证
 // oidcSignInCallback:  处理来自身份验证重定向的回调。有一个可选的url参数
 // authenticateOidcSilent:  登录时进行身份验证。如果未登录，则不进行重定向
 // getOidcUser:  从oidc客户端获取用户，并在vuex store中更新。返回一个承诺
 // signOutOidc:  在开放id提供程序中签出用户
 // removeOidcUser: 在vuex和浏览器存储中签出用户，但不在开放id提供程序中

 export const oidcAuthWeixin = {
     // 授权服务器地址
     authority: "https://auth.zhanshangxiu.com",
     // 客户端id
     clientId: clientId,
     // 授权回调地址(需提供)
     redirectUri: process.env.NODE_ENV === 'development' ? `${location.origin  + '/'}OidcCallback` : `${location.origin  + '/'}OidcCallback`,
     // redirectUri:`${location.origin  + '/'}/OidcCallback`,
     // 响应类型
     responseType: "code",
     // 作用域
     scope: "openid showonline-api profile",
     // 是否加载用户信息
     loadUserInfo: true,
     // 调起微信手机登录
     acr_values: "idp:Weixin bind-thirdparty:Weixin",

     automaticSilentRenew: true,

     postLogoutRedirectUri: `${location.origin  + '/'}home/index`,

     automaticSilentSignin: true, // If true vuex-oidc will try to silently signin unauthenticated users on public routes. Defaults to true

     extraQueryParams: {
         // 退出登录回调链接，会覆盖post_logout_redirect_uri，可选
         postLogoutRedirectUri: `${location.origin  + '/'}redirectUrl`
     },
 }
 export const oidcSettingsLinkedIn = {
     // 授权服务器地址
     authority: "https://auth.zhanshangxiu.com",
     // 客户端id
     clientId: clientId,
     // 授权回调地址(需提供)
     redirectUri: process.env.NODE_ENV === 'development' ? `${location.origin  + '/'}OidcCallback` : `${location.origin  + '/'}OidcCallback`,
     // redirectUri:`${location.origin  + '/'}/OidcCallback`,
     // 响应类型
     responseType: "code",
     // 作用域
     scope: "openid showonline-api profile",
     // 是否加载用户信息
     loadUserInfo: true,
     // 调起微信手机登录
     acrValues: "idp:LinkedIn",

     // silentRedirectUri: `${location.origin  + '/'}/silentSignInCallback`,

     automaticSilentRenew: true,

     automaticSilentSignin: true, // If true vuex-oidc will try to silently signin unauthenticated users on public routes. Defaults to true

     extraQueryParams: {
         // 退出登录回调链接，会覆盖post_logout_redirect_uri，可选
         postLogoutRedirectUri: `${location.origin  + '/'}redirectUrl`
     }

 }
 export const oidcSettingsFacebook = {
     // 授权服务器地址
     authority: "https://auth.zhanshangxiu.com",
     // 客户端id
     clientId: clientId,
     // 授权回调地址(需提供)
     redirectUri: process.env.NODE_ENV === 'development' ? `${location.origin  + '/'}OidcCallback` : `${location.origin  + '/'}OidcCallback`,
     // redirectUri:`${location.origin  + '/'}/OidcCallback`,
     // 响应类型
     responseType: "code",
     // 作用域
     scope: "openid showonline-api profile",
     // 是否加载用户信息
     loadUserInfo: true,
     // 调起微信手机登录
     acrValues: "idp:Facebook",

     // silentRedirectUri: `${location.origin  + '/'}/silentSignInCallback`,

     automaticSilentRenew: true,

     automaticSilentSignin: true, // If true vuex-oidc will try to silently signin unauthenticated users on public routes. Defaults to true

     extraQueryParams: {
         // 退出登录回调链接，会覆盖post_logout_redirect_uri，可选
         postLogoutRedirectUri: `${location.origin  + '/'}redirectUrl`
     }

 }
 export const oidcSettingsTwitter = {
     // 授权服务器地址
     authority: "https://auth.zhanshangxiu.com",
     // 客户端id
     clientId: clientId,
     // 授权回调地址(需提供)
     redirectUri: process.env.NODE_ENV === 'development' ? `${location.origin  + '/'}OidcCallback` : `${location.origin  + '/'}OidcCallback`,
     // redirectUri:`${location.origin  + '/'}/OidcCallback`,
     // 响应类型
     responseType: "code",
     // 作用域
     scope: "openid showonline-api profile",
     // 是否加载用户信息
     loadUserInfo: true,
     // 调起微信手机登录
     acrValues: "idp:Twitter",

     // silentRedirectUri: `${location.origin  + '/'}/silentSignInCallback`,

     automaticSilentRenew: true,

     automaticSilentSignin: true, // If true vuex-oidc will try to silently signin unauthenticated users on public routes. Defaults to true

     extraQueryParams: {
         // 退出登录回调链接，会覆盖post_logout_redirect_uri，可选
         postLogoutRedirectUri: `${location.origin  + '/'}redirectUrl`
     }

 }